import {
  DistributionType,
  DistributionTypeEnum,
  DropFilterInput,
  DropItemFragment,
  FilterOptionStatus,
  Genre,
  getContentTypeUrl,
  useCurrentCustomer,
  useGetApplicationFeatureContentsQuery,
  useGetDropFilterOptionsQuery,
  useGetDropsQuery,
  useGetSchedulesMaintenanceQuery,
} from '@collection-platform-frontend/api';
import {
  cn,
  format,
  isAfter,
  reverseProxySSR,
} from '@collection-platform-frontend/shared';
import {
  DropBannerItem,
  DropLinkBannerItem,
} from '@collection-platform-frontend/storefront';
import {
  Button,
  HeroSlider,
  Image,
  Loading,
  Screen,
  Section,
  Tag,
  Typography,
} from '@collection-platform-frontend/ui';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { GetServerSideProps } from 'next';
import NextImage, { StaticImageData } from 'next/image';
import Link from 'next/link';
import { AuthAction, withAuthUser } from 'next-firebase-auth';
import { useCallback, useMemo, useState } from 'react';

import { PageLayoutWithFilter } from '../components/layout/layout-with-filter';
import { useRouter } from '../libs/router';
import newCloudImage from '../public/assets/new.png';
import qaCloudImage from '../public/assets/q&a.png';
import { DropsBanners } from '../views/banner/drops-banner';
import { HomeFilterSearch } from '../views/filter-search/home-filter-search';
import {
  CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY,
  SensitiveContentRememberCheckbox,
} from '../views/modal/sensitive-content-alert';

// const contractItemsMaxAmount = 8;
const currentDropBannersMaxAmount = 6;

const currentApplicationId =
  process.env.NEXT_PUBLIC_X_COLLECTION_APPLICATION_ID;
const wordSearchEnabled =
  process.env.NEXT_PUBLIC_FEATURE_WORD_SEARCH_RELEASED === 'true';
const rfidScannerEnabled =
  process.env.NEXT_PUBLIC_RFID_SCANNER_RELEASED === 'true';

type HeroSliderData = {
  image: string | StaticImageData;
  url?: string;
};

const createMaintenanceMessage = (
  startAt: Date,
  endAt: Date,
): string | undefined => {
  if (!isAfter('2024-01-03 23:59:59')) {
    return `誠に勝手ながら 2023年12月29日(金)〜2024年1月3日(木)は
    弊社サポート業務をお休みさせていただきます。
    皆様には大変ご不便をおかけいたしますが、何卒ご理解の程お願い申し上げます。`;
  }

  return `システムメンテナンスのお知らせ \n${format(
    startAt,
    'MM月DD日 HH:mm',
  )}〜${format(endAt, 'MM月DD日 HH:mm')}`;
};

type CloudearGenre = Genre & {
  checked?: boolean;
};

const loadLocalSetting = () => {
  const checked =
    localStorage.getItem(CLOUDEAR_SENSITIVE_CONTENT_STORAGE_KEY) ===
    SensitiveContentRememberCheckbox.CHECKED;

  if (checked) {
    return [FilterOptionStatus.Gua, FilterOptionStatus.Sta];
  } else {
    return [FilterOptionStatus.Sta];
  }
};

const IndexPage = () => {
  // const [contractSectionOpen] = useState(false);
  const [dropSectionOpen, setDropSectionOpen] = useState(false);
  const [dropsFilterInput, setDropsFilterInput] = useState<{
    category?: string;
    genres?: CloudearGenre[];
    filterOptions?: FilterOptionStatus[];
    searchText?: string;
  }>({
    category: null,
    genres: null,
    filterOptions: loadLocalSetting(),
    searchText: '',
  });
  const { isReady, push } = useRouter();

  const { authUser, customer } = useCurrentCustomer();
  const myCustomer = customer?.myCustomer;

  const dropFilterOptionsQueryArgs = useMemo(() => {
    const now = new Date().toISOString();
    return {
      isExpiredAtGreaterThan: now,
    };
  }, []);

  // Get drop filter options
  const [{ data: dropFilterOptionsResult }] = useGetDropFilterOptionsQuery({
    pause: !isReady,
    requestPolicy: 'cache-and-network',
    variables: {
      applicationId: currentApplicationId,
      includesGua: dropsFilterInput.filterOptions?.includes(
        FilterOptionStatus.Gua,
      ),
      ...dropFilterOptionsQueryArgs,
    },
  });

  const dropFilterOptions = dropFilterOptionsResult?.dropFilterOptions?.edges;

  const [{ data: applicationFeatureContentsResult }] =
    useGetApplicationFeatureContentsQuery({
      pause: !isReady,
      requestPolicy: 'cache-and-network',
      variables: {
        applicationId: currentApplicationId,
      },
    });

  const featureContents =
    applicationFeatureContentsResult?.applicationFeatureContents.edges;

  const [{ data: scheduleMaintenancesResult }] =
    useGetSchedulesMaintenanceQuery({
      pause: !isReady,
      requestPolicy: 'cache-and-network',
    });
  const maintenance =
    scheduleMaintenancesResult?.scheduledMaintenancesForFuture;

  const dropQueryArgs = useMemo(() => {
    const now = new Date().toISOString();
    const args: DropFilterInput = {
      applicationId: currentApplicationId,
      isExpiredAtGreaterThan: now,
      category: dropsFilterInput.category,
      filterOptions: dropsFilterInput.filterOptions,
      genres: null,
      distributionType: null,
      searchText: null,
    };
    if (dropsFilterInput.genres?.length) {
      args.genres = dropsFilterInput.genres
        .map((e) => (e.checked ? e.value : ''))
        .filter((e) => e);
    }
    if (dropsFilterInput.searchText) {
      args.searchText = dropsFilterInput.searchText;
    }
    return args;
  }, [dropsFilterInput]);

  const [{ data: purchaseItemResult, fetching: purchaseDropsFetching }] =
    useGetDropsQuery({
      pause: !isReady,
      requestPolicy: 'network-only',
      variables: {
        ...dropQueryArgs,
        distributionType: DistributionTypeEnum.BY_PURCHASE,
      },
    });
  const purchaseDropItems = purchaseItemResult?.drops;

  const [{ data: dropResult, fetching: dropDistributionFetching }] =
    useGetDropsQuery({
      pause: !isReady,
      requestPolicy: 'network-only',
      variables: {
        ...dropQueryArgs,
        distributionType: DistributionTypeEnum.BY_DROP,
      },
    });
  const dropItems = dropResult?.drops;

  // const { contractItems, contractDrops: contractDropsFetched } =
  //   useContractDrops({
  //     applicationId: currentApplicationId,
  //     limit: contractSectionOpen ? undefined : contractItemsMaxAmount,
  //   });
  // XXX: コントラクト一覧から消す
  // const contractDrops = contractDropsFetched
  //   ? contractDropsFetched.filter(
  //       (cdf) =>
  //         ![
  //           'SGKCLDR_gr_pbr_ns245',
  //           'SGKCLDR_cb_dinosaur2024',
  //           'SGKCLDR_conan',
  //           'SGKCLDR_Shinkikaku',
  //           'SGKCLDR_ec_mx_ecbtest0906',
  //           'SGKCLDR_bk_pbd_ubgez7',
  //           'SGKCLDR_bk_pbd_hkip9g7',
  //           'SGKCLDR_gr_pdp_tny5vqtb',
  //           'SGKCLDR_bk_pbd_hyms2c6',
  //           'SGKCLDR_bk_pbd_bkypv72',
  //           'SGKCLDR_gr_3cs_spih6i8z',
  //         ].includes(cdf.name),
  //     )
  //   : [];

  const heroSliderItems = useMemo(() => {
    if (!featureContents) {
      return null;
    }

    return featureContents.map(({ node }) => {
      return {
        image: node.asset?.url,
        url: getContentTypeUrl(node),
      };
    });
  }, [featureContents]);

  const maintenanceMessage = useMemo(() => {
    if (!maintenance || maintenance.length === 0) {
      return null;
    }

    return createMaintenanceMessage(
      maintenance?.[0].startAt,
      maintenance?.[0].endAt,
    );
  }, [maintenance]);

  const currentPurchaseBanners = useMemo(() => {
    if (!purchaseDropItems) {
      return [];
    }

    return purchaseDropItems.edges.map(({ node }) => node);
  }, [purchaseDropItems]);

  const currentDropBanners = useMemo(() => {
    if (!dropItems) {
      return [];
    }

    return dropItems.edges.map(({ node }) => node);
  }, [dropItems]);

  // MEMO: もらえるのセクションから除外するアイテム
  const filterItems =
    (process.env.FILTERED_DROP_ITEM_SLUGS ?? '').split(',') ?? [];
  const filteredDropItems = currentDropBanners.filter(
    // TODO: 非表示機能実装時に削除
    // MEMO: もらえるから非表示になり、買ったらもらえるに表示される。
    ({ slug }) =>
      !filterItems.includes(slug) &&
      ![
        'at-gap-aa24',
        'gr-pbr-ns245',
        'bk-pbd-wytpn',
        'ec-mx-cecs7wyi',
        'ec-mx-rmcsjqz9',
        'ec-mx-ahcs5uwb',
        'ec-mx-hacsffg3',
        'ec-mx-kdcsurvp',
        'ec-mx-sacs6y1d',
        'ec-mx-tacsmk4d',
        'ec-mx-vbcs9pox',
        'ec-mx-mkcste8j',
        'ec-mx-ktcscas2',
        'ec-mx-gbcs4j7p',
        'ec-mx-shcsug4k',
        'ec-mx-sscs2rj8',
        'ec-mx-hhcs8kzy',
        'bk-pbd-np24qw',
        'bk-pbd-ubgez7',
        'ec-mx-ecbtest0906',
        'gr-pdp-tny5vqtb',
        'bk-pbd-hyms2c6',
        'bk-pbd-bkypv72',
        'bk-pbd-hkip9g7',
        'bk-pbd-bkypv72',
        'bk-pbd-gt01p3yz',
        'bk-pbd-gt01drp8i',
      ].includes(slug),
  );
  // TODO: クエリ側を修正する
  const dropToGiftCodeDropItems = currentDropBanners.filter(
    ({ slug, startAt }) => filterItems.includes(slug) && isAfter(startAt),
  );

  const [{ data: giftCodeItemResult }] = useGetDropsQuery({
    pause: !isReady,
    requestPolicy: 'network-only',
    variables: {
      ...dropQueryArgs,
      distributionType: DistributionTypeEnum.BY_GIFT_CODE,
    },
  });

  // TODO: クエリ側を修正する
  const giftCodeDropItemsMerged = [
    ...dropToGiftCodeDropItems.map((item) => ({ node: item })),
    ...(giftCodeItemResult ? giftCodeItemResult.drops.edges : []),
  ];
  const giftCodeDropItems = giftCodeDropItemsMerged.filter(({ node }) => {
    // MEMO:bannerImageAssetUrlがあるもののみ表示
    // MEMO: もらえるのセクションから除いたものもここで表示される。さらに下の条件に入れればトップ画面には表示されない。
    // TODO: 非表示機能実装時に削除
    if (
      [
        'cb-zn-gd4vcb1',
        'cb-zn-gd4ze92',
        'cb-zn-gdypb3',
        'ec-mx-cecs7wyi',
        'ec-mx-rmcsjqz9',
        'ec-mx-ahcs5uwb',
        'ec-mx-hacsffg3',
        'ec-mx-kdcsurvp',
        'ec-mx-sacs6y1d',
        'ec-mx-tacsmk4d',
        'ec-mx-vbcs9pox',
        'ec-mx-mkcste8j',
        'ec-mx-ktcscas2',
        'ec-mx-gbcs4j7p',
        'ec-mx-shcsug4k',
        'ec-mx-sscs2rj8',
        'ec-mx-hhcs8kzy',
        'bk-pbd-np24qw',
        'ec-mx-ecbtest0906',
        'gr-pdp-tny5vqtb',
      ].includes(node.slug)
    ) {
      return false;
    }
    return !!node.bannerImageAssetUrl || node.startAt;
  });

  const currentGiftCodeBanners = useMemo(() => {
    if (!giftCodeDropItems) {
      return [];
    }

    const dropBanners = giftCodeDropItems.map(({ node }) => node);
    if (dropSectionOpen) {
      return dropBanners;
    }

    return dropBanners.slice(0, currentDropBannersMaxAmount);
  }, [dropSectionOpen, giftCodeDropItems]);

  const onExternalLink = useCallback((value: string) => {
    window.open(value, '_blank');
  }, []);

  const onLink = useCallback(
    (value: string) => {
      if (value.startsWith('/')) {
        push(value);
      } else {
        onExternalLink(value);
      }
    },
    [push, onExternalLink],
  );

  const onSectionOpen = useCallback((toggle) => {
    toggle((prev) => !prev);
  }, []);

  const onSubmitDropFilter = ({
    category = dropsFilterInput.category,
    genres = dropsFilterInput.genres,
    searchText = dropsFilterInput.searchText,
  }: {
    category?: string;
    genres?: Genre[];
    searchText?: string;
  }) => {
    setDropsFilterInput({ ...dropsFilterInput, category, genres, searchText });
  };

  const onResetDropFilterCategories = () => {
    setDropsFilterInput({
      ...dropsFilterInput,
      category: null,
      genres: null,
    });
  };

  const onSubmitDropFilterWithOptions = (value: boolean) => {
    setDropsFilterInput({
      filterOptions: value
        ? [FilterOptionStatus.Gua, FilterOptionStatus.Sta]
        : [FilterOptionStatus.Sta],
    });
  };

  const onSearchTextChange = (searchText: string) => {
    setDropsFilterInput({ ...dropsFilterInput, searchText });
  };

  return (
    <PageLayoutWithFilter
      authUser={authUser}
      user={myCustomer}
      onRefresh={onSubmitDropFilterWithOptions}
    >
      <div className="w-full">
        {/* 上での挙動 */}
        {wordSearchEnabled ? (
          <Section classname="px-4 py-4">
            <HomeFilterSearch
              filterOptions={dropFilterOptions}
              onSubmit={onSubmitDropFilter}
              onReset={onResetDropFilterCategories}
              currentFilter={dropsFilterInput}
              searchText={dropsFilterInput.searchText}
              onSearchTextChange={onSearchTextChange}
            />
          </Section>
        ) : null}
        <Screen className="py-16 mt-2 bg-cloudear-blue md:px-0">
          {featureContents && (
            <HeroSlider<HeroSliderData>
              source={heroSliderItems}
              itemRenderer={({ image }, active) => {
                if (image === null) {
                  return null;
                }
                return (
                  <NextImage
                    src={image}
                    alt="spotlight_item"
                    width={1200}
                    height={600}
                    className={cn(
                      'overflow-hidden border-2 border-white rounded-3xl hover:cursor-pointer aspect-2/1',
                      !active && 'opacity-50',
                    )}
                  />
                );
              }}
              onSelect={(item) => onLink(item.url)}
            />
          )}
        </Screen>

        {rfidScannerEnabled && (
          <Section classname="flex px-4 py-16">
            <Link href={`/rfid-scanner`}>
              <Tag>RFID読み取り</Tag>
            </Link>
          </Section>
        )}

        {/* お知らせ */}
        {maintenanceMessage && (
          <Section classname="flex justify-center px-4 py-12 sm:p-12">
            <div className="relative">
              <Typography
                variant="body"
                className="absolute inline-block px-4 py-2 text-white -translate-x-1/2 rounded-full bg-cloudear-blue z-element -top-4 left-1/2 whitespace-nowrap"
              >
                CLOUDEARからのお知らせ
              </Typography>
              <Typography
                variant="body"
                className="w-full px-8 pt-8 pb-6 break-words whitespace-pre-wrap border rounded-full sm:whitespace-normal z-base border-wallet-light-inactive/50 bg-black/5"
              >
                {maintenanceMessage}
              </Typography>
            </div>
          </Section>
        )}

        {/* Dropスライダー */}
        <Screen className="bg-[#EFEFF8] py-16">
          {currentPurchaseBanners.length > 0 && (
            <Section title="買える！">
              <div className="mb-24">
                <DropsBanners<DropItemFragment>
                  source={currentPurchaseBanners}
                  fetching={purchaseDropsFetching}
                  itemRenderer={({
                    slug,
                    name,
                    price,
                    thumbnailAssetUrl,
                    startAt,
                  }) => (
                    <DropBannerItem
                      key={slug}
                      image={thumbnailAssetUrl}
                      title={name}
                      startAt={startAt && new Date(startAt)}
                      price={price}
                      linkPath={`/mint/${slug}`}
                    />
                  )}
                />
              </div>
            </Section>
          )}

          {filteredDropItems.length > 0 && (
            <Section title="もらえる！">
              <div className="mb-24">
                <DropsBanners<DropItemFragment>
                  source={filteredDropItems}
                  fetching={dropDistributionFetching}
                  itemRenderer={({
                    slug,
                    name,
                    thumbnailAssetUrl,
                    startAt,
                  }) => (
                    <DropBannerItem
                      key={slug}
                      image={thumbnailAssetUrl}
                      title={name}
                      startAt={startAt && new Date(startAt)}
                      linkPath={`/mint/${slug}`}
                    />
                  )}
                />
              </div>
            </Section>
          )}
        </Screen>

        {/* drop一覧 */}
        {currentGiftCodeBanners.length > 0 && (
          <Section title="買ったらもらえる！" classname="px-4 py-16 ">
            <Typography variant="body2" className="mb-8 -mt-4">
              * アイテム受取開始日は予定なく変更となる場合がございます
            </Typography>
            <div className="grid w-full grid-cols-1 gap-6 mb-12 md:grid-cols-2">
              {currentGiftCodeBanners.map(
                ({
                  name,
                  description,
                  slug,
                  startAt,
                  bannerImageAssetUrl,
                  externalContentUrl,
                  distributionType,
                }) => {
                  if (slug === 'ec-mx-co2024') {
                    return (
                      <DropLinkBannerItem
                        key={slug}
                        image={bannerImageAssetUrl}
                        title={name}
                        desctiption={description}
                        externalLink={externalContentUrl}
                        startAt={startAt && new Date(startAt)}
                        onExternalLink={onExternalLink}
                        externalLinkButtonLabel={'回答ページはこちら'}
                        distributionType={DistributionTypeEnum.BY_DROP}
                      />
                    );
                  }
                  return (
                    <DropLinkBannerItem
                      key={slug}
                      image={bannerImageAssetUrl}
                      title={name}
                      desctiption={description}
                      externalLink={externalContentUrl}
                      startAt={startAt && new Date(startAt)}
                      onLink={() => onLink(`/mint/${slug}`)}
                      onExternalLink={onExternalLink}
                      distributionType={distributionType as DistributionType}
                    />
                  );
                },
              )}
            </div>
            {giftCodeDropItems &&
              giftCodeDropItems.length > currentDropBannersMaxAmount && (
                <div className="flex justify-center w-full">
                  <Button
                    className="text-cloudear-blue border-cloudear-blue w-fit"
                    onClick={() => onSectionOpen(setDropSectionOpen)}
                  >
                    <div className="flex space-x-2">
                      すべてのデジタルピースを表示する
                      {dropSectionOpen ? (
                        <ChevronUpIcon className="w-5 h-5" />
                      ) : (
                        <ChevronDownIcon className="w-5 h-5" />
                      )}
                    </div>
                  </Button>
                </div>
              )}
          </Section>
        )}

        {/* 作品一覧 */}
        {/*<Screen className="bg-[#EFEFF8] py-16">*/}
        {/*  <Section classname="w-full max-w-7xl" title="作品一覧">*/}
        {/*    <div className="flex flex-wrap gap-4 py-8 sm:justify-center sm:gap-6 sm:p-8">*/}
        {/*      {contractDrops.map(({ name, displayName }, index) => (*/}
        {/*        <Link key={index} href={`/contract/${name}`}>*/}
        {/*          <Tag>{displayName}</Tag>*/}
        {/*        </Link>*/}
        {/*      ))}*/}
        {/*    </div>*/}
        {/*    {contractItems && contractItems.length > contractItemsMaxAmount && (*/}
        {/*      <div className="flex justify-center w-full">*/}
        {/*        <Button*/}
        {/*          className="text-cloudear-blue border-cloudear-blue w-fit"*/}
        {/*          onClick={() => onSectionOpen(setContractSectionnOpen)}*/}
        {/*        >*/}
        {/*          <div className="flex space-x-2">*/}
        {/*            すべての作品を表示する*/}
        {/*            {contractSectionOpen ? (*/}
        {/*              <ChevronUpIcon className="w-5 h-5" />*/}
        {/*            ) : (*/}
        {/*              <ChevronDownIcon className="w-5 h-5" />*/}
        {/*            )}*/}
        {/*          </div>*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </Section>*/}
        {/*</Screen>*/}

        {/* 初めての方リンク先 */}
        <Section classname="px-4 py-16">
          <div className="flex flex-col w-full gap-8 md:flex-row">
            <div
              onClick={() => onLink('/beginners')}
              className="flex flex-col items-center w-full px-8 py-16 space-y-2 text-white bg-cloudear-blue rounded-3xl hover:cursor-pointer"
            >
              <Image
                alt={`new-link`}
                src={newCloudImage}
                height={80}
                objectFit="contain"
              />
              <Typography variant="h1">はじめての方へ</Typography>
              <Typography variant="caption">
                CLOUDEARとその使い方について
              </Typography>
            </div>
            <div
              onClick={() => onExternalLink('https://support.cldr.jp/hc/ja')}
              className="flex flex-col items-center w-full px-8 py-16 space-y-2 text-white bg-cloudear-blue rounded-3xl hover:cursor-pointer"
            >
              <Image
                alt={`qanda-link`}
                src={qaCloudImage}
                height={80}
                objectFit="contain"
              />
              <Typography variant="h1">よくある質問</Typography>
              <Typography variant="caption">
                よくある質問やお問い合わせ
              </Typography>
            </div>
          </div>
        </Section>
      </div>
    </PageLayoutWithFilter>
  );
};

export const getServerSideProps: GetServerSideProps =
  process.env.FEATURE_RENEWAL_TOP_RELEASED === 'true'
    ? null
    : reverseProxySSR('/');

export default withAuthUser({
  whenAuthed: AuthAction.RENDER,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: Loading,
})(IndexPage);
